@charset "utf-8";

@import "variables";


// key-curtain
.key-curtain{
	overflow: hidden;
	position: relative;
	.curtain-inner{
		// line-height: 0;
		display: block;
		opacity: 0;
		position: relative;
		left: 100px;
		transition: left 1.5s;
	}
}
.key-curtain:after{
	content: '';
	opacity: 1;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: #000;
	transform: translateX(-101%);
}
.key-curtain.on{
	.curtain-inner{
		left: 0;
		animation-name: curtainInner;
		animation-duration: 0s;
		animation-delay: .6s;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
	}
}
.key-curtain.on:after{
	animation-name: curtainStart,curtainEnd;
	animation-duration: .6s,.6s;
	animation-timing-function: cubic-bezier(.86,0,.07,1),cubic-bezier(.86,0,.07,1);
	animation-delay: 0s,.6s;
	animation-direction: alternate;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}
@keyframes curtainStart {
	0%{transform: translateX(-101%);}
	100%{transform: translateX(0);}
}
@keyframes curtainEnd {
	0%{transform: translateX(0);}
	100%{transform: translateX(101%);}
}
@keyframes curtainInner {
	0%{opacity: 0;}
	100%{opacity: 1;}
}